import { faPlusSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  SafeAnchor,
} from "react-bootstrap";
import FormInput from "../../components/FormInput";
import FormInputGroup from "../../components/FormInputGroup";
import Loading from "../../components/Loading";
import useCustomerTypes from "../../hooks/useCustomerTypes";
import useLoggedInUser from "../../hooks/useLoggedInUser";
import useRequestTypes from "../../hooks/useRequestTypes";
import AlertMessage from "../../models/AlertMessage";
import Case from "../../models/Case";
import RegistrarRequestField, {
  getRequestorFieldsByUserType,
  getSubjectOfRequestFieldsByUserType,
} from "../../models/RegistarRequestField";
import { RegistrarRequest } from "../../models/RegistrarRequest";
import RequestError from "../../models/RequestError";
import Undefinable from "../../models/Undefinable";
import { ShibbUserType } from "../../models/UserType";
import CaseService from "../../services/CaseService";

const Home = (): JSX.Element => {
  const localStorageRequestKey = "request";
  const newRequest = {
    customerType: "",
    customerTypeDescription: "",
    requestType: "",
    requestDescription: "",
  };
  const loggedInUser = useLoggedInUser();
  const customerTypes = useCustomerTypes();
  const [requestTypes, setRequestTypesByCustomerType] = useRequestTypes();
  const [loading, setLoading] = useState<boolean>(true);

  const [request, setRequest] = useState<RegistrarRequest>({ ...newRequest });
  const [attachments, setAttachments] = useState<Undefinable<File>[]>([
    undefined,
  ]);
  const [isShibbUser, setIsShibbUser] = useState<boolean>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [alertMessage, setAlertMessage] =
    useState<Undefinable<AlertMessage>>(undefined);
  const [disabled, setDisabled] = useState<boolean>(false);

  const login = () => {
    if (request?.customerType) {
      localStorage.setItem(localStorageRequestKey, JSON.stringify(request));
    }
    window.location.assign(`${window.location.origin}/Shibboleth.sso/Login`);
  };

  const onSelectCustomerType = async (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const customerType = e.target.value;
    setIsShibbUser(
      (_.values(ShibbUserType) as string[]).includes(customerType)
    );
    if (isLoggedIn) {
      setRequest({
        ...request,
        customerType,
        firstName: loggedInUser?.firstName,
        lastName: loggedInUser?.lastName,
        email: loggedInUser?.email,
        department: loggedInUser?.department,
        contactId: loggedInUser?.contactId,
      });
    } else {
      setRequest({
        ...request,
        customerType,
      });
    }
    const newRequestTypes = setRequestTypesByCustomerType(
      customerType,
      customerTypes
    );
    if (
      request?.requestType &&
      newRequestTypes?.length &&
      !newRequestTypes?.map((x) => x.value).includes(request?.requestType)
    ) {
      setRequest({
        ...request,
        requestType: "",
      });
    }
  };

  const resetRequestForm = () => {
    setRequest({ ...newRequest });
    setAttachments([undefined]);
    setDisabled(false);
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setLoading(true);
      setDisabled(true);
      const response = await CaseService.saveCase(
        new Case(request),
        attachments
      );
      if (response?.data && response?.data[0]?.success) {
        setAlertMessage({
          content: (
            <>
              <span>You have successfully submitted a request.</span>
              {response?.data[0].id && (
                <span>
                  &nbsp;Your case number is&nbsp;
                  <strong>{response?.data[0]?.id}.</strong>
                </span>
              )}
              <span>
                &nbsp;
                <SafeAnchor onClick={() => resetRequestForm()}>
                  <b>Click here</b>
                </SafeAnchor>
                &nbsp;if you would like to submit another request.
              </span>
            </>
          ),
          variant: "success",
        });
      } else {
        // eslint-disable-next-line no-console
        console.error(response?.data[0]?.errors);
        setAlertMessage({
          content:
            response?.data[0]?.errors[0]?.message ||
            "An error has occurred while submitting your request",
          variant: "danger",
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError<RequestError>;
      setAlertMessage({
        content:
          axiosError?.response?.data?.message ||
          "An error has occurred while submitting your request",
        variant: "danger",
      });
    } finally {
      window.scrollTo(0, 0);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerTypes) {
      if (customerTypes.length === 0) {
        setAlertMessage({
          content: "An error has occurred while loading customer types",
          variant: "danger",
        });
      }
      setLoading(false);
    }
    if (customerTypes && requestTypes && loggedInUser?.ppid) {
      setIsLoggedIn(true);
      const requestJson = localStorage.getItem(localStorageRequestKey);
      if (requestJson !== null) {
        const savedRequest = JSON.parse(requestJson) as RegistrarRequest;
        setRequestTypesByCustomerType(
          savedRequest?.customerType,
          customerTypes
        );
        setRequest({
          ...savedRequest,
          firstName: loggedInUser?.firstName,
          lastName: loggedInUser?.lastName,
          email: loggedInUser?.email,
          department: loggedInUser?.department,
          contactId: loggedInUser?.contactId,
        });
        localStorage.removeItem(localStorageRequestKey);
      }
    }
  }, [
    customerTypes,
    requestTypes,
    loggedInUser,
    setRequestTypesByCustomerType,
  ]);

  return (
    <section aria-labelledby="home" className="section">
      <Container>
        {loading && <Loading />}
        <h1 id="page-title">Registrar Request Management</h1>
        <hr className="divider-ornamental" />
        {alertMessage !== undefined && (
          <Row className="mt-4">
            <Col>
              <Alert
                variant={alertMessage.variant}
                dismissible
                onClose={() => setAlertMessage(undefined)}
              >
                {alertMessage.content}
              </Alert>
            </Col>
          </Row>
        )}
        <Form onSubmit={onSubmit}>
          <p id="required-field" className="small">
            <span className="text-danger">*</span> Required Field
          </p>
          <Row>
            <Col md="6">
              <fieldset>
                <FormInput
                  field={RegistrarRequestField.CUSTOMER_TYPE}
                  request={request}
                  onChange={onSelectCustomerType}
                  options={customerTypes}
                  inputGroupAppendContent={
                    isShibbUser && !isLoggedIn ? (
                      <InputGroup.Append className="login-button">
                        <Button
                          variant="primary"
                          aria-label="Login"
                          onClick={login}
                        >
                          Login
                        </Button>
                      </InputGroup.Append>
                    ) : undefined
                  }
                  disabled={disabled}
                />
                {request.customerType === "Other" && (
                  <FormInput
                    field={RegistrarRequestField.CUSTOMER_TYPE_DESCRIPTION}
                    request={request}
                    setRequest={setRequest}
                    disabled={disabled}
                  />
                )}
              </fieldset>
              {request.customerType && (
                <>
                  <FormInputGroup
                    id="requestor"
                    label="Requestor"
                    fields={getRequestorFieldsByUserType(request.customerType)}
                    request={request}
                    setRequest={setRequest}
                    helperContent={
                      isShibbUser && !isLoggedIn ? (
                        <Alert variant="info">
                          Please{" "}
                          <Alert.Link as={SafeAnchor} onClick={login}>
                            login
                          </Alert.Link>{" "}
                          to autofill your information.
                        </Alert>
                      ) : undefined
                    }
                    disabled={disabled}
                  />
                  <FormInputGroup
                    id="subjectOfRequest"
                    label="Student Information (if applicable)"
                    fields={getSubjectOfRequestFieldsByUserType(
                      request.customerType
                    )}
                    request={request}
                    setRequest={setRequest}
                    disabled={disabled}
                  />
                </>
              )}
            </Col>
            <Col md="6">
              {request.customerType && (
                <>
                  <fieldset>
                    <legend>Request Details</legend>
                    <FormInput
                      field={RegistrarRequestField.REQUEST_TYPE}
                      request={request}
                      setRequest={setRequest}
                      options={requestTypes}
                      disabled={disabled}
                    />
                    <FormInput
                      field={RegistrarRequestField.REQUEST_DESCRIPTION}
                      request={request}
                      setRequest={setRequest}
                      disabled={disabled}
                    />
                    <div className="attachments form-group">
                      <span className="d-inline-block mb-2">Attachments</span>
                      {_.range(attachments.length).map((idx) => (
                        <InputGroup key={`attachment-${idx}`}>
                          <Form.File custom className="mr-2">
                            <Form.File.Label htmlFor={`attachment-${idx}`}>
                              {attachments[idx]?.name ?? "No file chosen"}
                            </Form.File.Label>
                            <Form.File.Input
                              id={`attachment-${idx}`}
                              name={`attachment-${idx}`}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) =>
                                setAttachments(
                                  attachments.map((x, i) =>
                                    i === idx && event?.target?.files
                                      ? event.target.files[0]
                                      : x
                                  )
                                )
                              }
                              required={
                                attachments.length > 1 &&
                                !attachments[idx]?.name
                              }
                              aria-label="Attachment"
                              disabled={disabled}
                            />
                          </Form.File>
                          {attachments.length > 1 && (
                            <InputGroup.Append className="d-flex align-items-center justify-content-center">
                              <FontAwesomeIcon
                                size="lg"
                                icon={faTimes}
                                className="text-danger"
                                onClick={() =>
                                  !disabled &&
                                  setAttachments(
                                    attachments.filter((x, i) => i !== idx)
                                  )
                                }
                              />
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      ))}
                      <Button
                        variant="link"
                        onClick={() =>
                          setAttachments([...attachments, undefined])
                        }
                        disabled={disabled}
                      >
                        <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
                        <span>Add Attachment</span>
                      </Button>
                    </div>
                  </fieldset>
                  <fieldset className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      aria-label="Submit"
                      disabled={disabled}
                    >
                      Submit
                    </Button>
                  </fieldset>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </section>
  );
};

export default Home;

import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import AppService, { AppInfo } from "../../services/AppService";
import useSafeAsync from "../useSafeAsync";

const useAppInfo = (): AppInfo => {
  const safeAsync = useSafeAsync();
  const [appInfo, setAppInfo] = useState<AppInfo>({
    name: "",
    version: "",
  });

  useEffect(() => {
    (async () => {
      const appInfoResponse = (await safeAsync(
        AppService.getAppInfo()
      )) as AxiosResponse<AppInfo>;
      let { name, version } = appInfoResponse?.data || {
        name: "",
        version: "",
      };
      name = name || "";
      if (version) {
        version = version.startsWith("v") ? version : `v${version}`;
      } else {
        version = "";
      }
      setAppInfo({ name, version });
    })();
  }, [safeAsync]);

  return appInfo;
};

export default useAppInfo;

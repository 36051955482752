import React, { useEffect } from "react";
import useSafeAsync from "../../hooks/useSafeAsync";
import ShibbService from "../../services/ShibbService";

const Login = (): JSX.Element => {
  const safeAsync = useSafeAsync();

  useEffect(() => {
    safeAsync(ShibbService.getLoggedInUser())
      .then((loggedInUser) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(loggedInUser));
        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        // eslint-disable-next-line no-debugger
        debugger;
        window.close();
      });
  }, [safeAsync]);

  return <div />;
};

export default Login;

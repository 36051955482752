import {
  getRequestorFieldsByUserType,
  getSubjectOfRequestFieldsByUserType,
} from "./RegistarRequestField";
import { RegistrarRequest } from "./RegistrarRequest";

class Case implements RegistrarRequest {
  customerType?: string;

  customerTypeDescription?: string;

  requestType?: string;

  requestDescription?: string;

  firstName?: string;

  lastName?: string;

  email?: string;

  department?: string;

  contactId?: string;

  subjectFirstName?: string;

  subjectLastName?: string;

  subjectEmail?: string;

  subjectDOB?: string;

  subjectStudentId?: string;

  subjectNameAtTimeOfEnrollment?: string;

  subjectYearLastAttendedOrGraduated?: string;

  constructor(that: RegistrarRequest) {
    this.customerType = that.customerType;
    if (this.customerType === "Other") {
      this.customerTypeDescription = that.customerTypeDescription;
    }
    this.requestType = that.requestType;
    this.requestDescription = that.requestDescription;
    this.firstName = that.firstName;
    this.lastName = that.lastName;
    this.email = that.email;
    this.contactId = that.contactId;
    [
      ...getRequestorFieldsByUserType(this.customerType),
      ...getSubjectOfRequestFieldsByUserType(this.customerType),
    ].forEach((field) => {
      this[field.name] = that[field.name];
    });
  }
}

export default Case;

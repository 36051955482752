import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { setupCache } from "axios-cache-adapter";
import _ from "lodash";

interface AxiosCacheStore {
  store: Record<string, unknown>;
  removeItem(key: string, callback?: (err: unknown) => void): Promise<void>;
}

const AXIOS_INSTANCE = axios.create({
  responseType: "json",
  adapter: setupCache({
    invalidate: async (config, request) => {
      const axiosCacheStore = config?.store as AxiosCacheStore;

      // clears cache for endpoints containing URL when METHOD is [POST, PATCH, PUT, DELETE]
      /* istanbul ignore next */
      if (request?.method !== "get") {
        const url = request?.url?.toLowerCase();
        _.keys(axiosCacheStore.store).forEach((x) => {
          if (url && x.toLowerCase().includes(url)) {
            axiosCacheStore.removeItem(x);
          }
        });
      }
    },
    // disable query exclusion
    exclude: {
      query: false,
    },
  }).adapter,
});

AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE,
  PATCH,
}

export type ParameterMap = Record<string, string | number>;

class BaseService {
  private static formatUrl(baseUrl: string, pathParams: ParameterMap): string {
    let url = baseUrl;
    _.forEach(_.keys(pathParams), (x) => {
      url = _.replace(url, `{${x}}`, `${pathParams[x]}`);
    });
    return url;
  }

  private static call(
    method: HttpMethod,
    baseUrl: string,
    pathParams: ParameterMap = {},
    requestParams: ParameterMap = {},
    requestBody: Record<string, unknown> | FormData = {},
    requestHeaders: ParameterMap = {},
    useCache = false
  ): Promise<AxiosResponse> {
    const url = BaseService.formatUrl(baseUrl, pathParams);
    const config: AxiosRequestConfig = {
      params: requestParams,
      headers: requestHeaders,
      cache: {
        // if TRUE, 3 mins timeout, otherwise, don't cache (0 mins)
        maxAge: (useCache ? 3 : 0) * 60 * 1000,
        ignoreCache: !useCache,
      },
    };
    switch (method) {
      case HttpMethod.GET:
        return AXIOS_INSTANCE.get(url, config);
      case HttpMethod.POST:
        return AXIOS_INSTANCE.post(url, requestBody, config);
      case HttpMethod.PUT:
        return AXIOS_INSTANCE.put(url, requestBody, config);
      case HttpMethod.DELETE:
        return AXIOS_INSTANCE.delete(url, config);
      case HttpMethod.PATCH:
        return AXIOS_INSTANCE.patch(url, requestBody, config);
      default:
        /* istanbul ignore next */
        throw new Error(`Invalid method: ${method}`);
    }
  }

  static get(
    baseUrl: string,
    pathParams: ParameterMap = {},
    requestParams: ParameterMap = {},
    requestHeaders: ParameterMap = {},
    useCache = false,
    method = HttpMethod.GET
  ): Promise<AxiosResponse> {
    return BaseService.call(
      method,
      baseUrl,
      pathParams,
      requestParams,
      undefined,
      requestHeaders,
      useCache
    );
  }

  static post(
    baseUrl: string,
    pathParams: ParameterMap = {},
    requestParams: ParameterMap = {},
    requestBody: Record<string, unknown> | FormData = {},
    requestHeaders: ParameterMap = {},
    useCache = false,
    method = HttpMethod.POST
  ): Promise<AxiosResponse> {
    return BaseService.call(
      method,
      baseUrl,
      pathParams,
      requestParams,
      requestBody,
      requestHeaders,
      useCache
    );
  }

  static put(
    baseUrl: string,
    pathParams: ParameterMap = {},
    requestParams: ParameterMap = {},
    requestBody: Record<string, unknown> = {},
    requestHeaders: ParameterMap = {},
    useCache = false,
    method = HttpMethod.PUT
  ): Promise<AxiosResponse> {
    return BaseService.call(
      method,
      baseUrl,
      pathParams,
      requestParams,
      requestBody,
      requestHeaders,
      useCache
    );
  }

  static delete(
    baseUrl: string,
    pathParams: ParameterMap = {},
    requestParams: ParameterMap = {},
    requestHeaders: ParameterMap = {},
    useCache = false,
    method = HttpMethod.DELETE
  ): Promise<AxiosResponse> {
    return BaseService.call(
      method,
      baseUrl,
      pathParams,
      requestParams,
      undefined,
      requestHeaders,
      useCache
    );
  }

  static patch(
    baseUrl: string,
    pathParams: ParameterMap = {},
    requestParams: ParameterMap = {},
    requestBody: Record<string, unknown> = {},
    requestHeaders: ParameterMap = {},
    useCache = false,
    method = HttpMethod.PATCH
  ): Promise<AxiosResponse> {
    return BaseService.call(
      method,
      baseUrl,
      pathParams,
      requestParams,
      requestBody,
      requestHeaders,
      useCache
    );
  }
}

export default BaseService;

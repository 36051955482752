import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import useAppInfo from "../../hooks/useAppInfo";
import { Home, Login, PageNotFound } from "../../pages";

const App = (): JSX.Element => {
  const { version } = useAppInfo();

  return (
    <div className="main-content">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/login" render={() => <Login />} />
          <Route path="*" render={() => <PageNotFound />} />
        </Switch>
      </BrowserRouter>
      <span className="d-none">RRM {version}</span>
    </div>
  );
};

export default App;

import { AxiosResponse } from "axios";
import { RegistrarRequest } from "../../models/RegistrarRequest";
import SalesforcePicklistValue from "../../models/SalesforcePicklistValue";
import SalesforcePostResponse from "../../models/SalesforcePostResponse";
import Undefinable from "../../models/Undefinable";
import BaseService from "../BaseService";

class CaseService extends BaseService {
  static getCustomerTypes(): Promise<AxiosResponse<SalesforcePicklistValue[]>> {
    return super.get("/api/cases/customer-types");
  }

  static getRequestTypes(): Promise<AxiosResponse<SalesforcePicklistValue[]>> {
    return super.get("/api/cases/request-types");
  }

  static saveCase(
    request: RegistrarRequest,
    attachments: Undefinable<File>[]
  ): Promise<AxiosResponse<SalesforcePostResponse[]>> {
    const formData = new FormData();
    formData.append("request", JSON.stringify(request));
    attachments.forEach((x) => x && formData.append("attachments", x));
    return super.post("/api/cases", {}, {}, formData, {
      "Content-Type": "multipart/form-data",
    });
  }
}

export default CaseService;

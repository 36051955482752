import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import SalesforcePicklistValue from "../../models/SalesforcePicklistValue";
import Undefinable from "../../models/Undefinable";
import CaseService from "../../services/CaseService";
import useSafeAsync from "../useSafeAsync";

const useCustomerTypes = (): Undefinable<SalesforcePicklistValue[]> => {
  const safeAsync = useSafeAsync();
  const [customerTypes, setCustomerTypes] =
    useState<Undefinable<SalesforcePicklistValue[]>>();

  useEffect(() => {
    safeAsync(CaseService.getCustomerTypes())
      .then((response) => {
        const axiosResponse = response as AxiosResponse<
          SalesforcePicklistValue[]
        >;
        setCustomerTypes(axiosResponse?.data || []);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setCustomerTypes([]);
      });
  }, [safeAsync]);

  return customerTypes;
};

export default useCustomerTypes;

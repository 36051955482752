import { AxiosResponse } from "axios";
import Undefinable from "../../models/Undefinable";
import BaseService from "../BaseService";

export interface LoggedInUser {
  ppid: string;
  netId?: string;
  organizationalStatus?: string;
  firstName?: string;
  lastName?: string;
  hremplid?: string;
  contactId?: string;
  email?: string;
  department?: string;
  emoryId?: string;
}

interface ShibbAttribute {
  loggedInUser: LoggedInUser;
}

interface ShibbAttributeResponse {
  shibbAttribute: ShibbAttribute;
}

class ShibbService extends BaseService {
  private static getShibbAttributes(): Promise<
    AxiosResponse<ShibbAttributeResponse>
  > {
    return super.get("/api/shibb-attributes");
  }

  public static clearShibbAttributes(): Promise<AxiosResponse<string>> {
    return super.get("/api/clear-shibb-attributes");
  }

  static async getLoggedInUser(): Promise<Undefinable<LoggedInUser>> {
    const { data } = await ShibbService.getShibbAttributes();
    return data?.shibbAttribute?.loggedInUser;
  }
}

export default ShibbService;

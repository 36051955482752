import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import RegistrarRequestField from "../../models/RegistarRequestField";
import { RegistrarRequest } from "../../models/RegistrarRequest";
import SalesforcePicklistValue from "../../models/SalesforcePicklistValue";

interface FormInputProps {
  groupId?: string;
  field: RegistrarRequestField;
  request: RegistrarRequest;
  setRequest?: (request: RegistrarRequest) => void;
  onChange?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => Promise<void>;
  options?: SalesforcePicklistValue[];
  inputGroupAppendContent?: JSX.Element;
  disabled: boolean;
}

const FormInput = ({
  groupId,
  field,
  request,
  setRequest,
  onChange,
  options,
  inputGroupAppendContent,
  disabled,
}: FormInputProps): JSX.Element => {
  if (!request[field.name]) {
    request[field.name] = "";
  }

  return (
    <>
      <Form.Group
        controlId={
          groupId === undefined ? field.name : `${groupId}-${field.name}`
        }
      >
        <Form.Label>
          {field.required ? (
            <>
              {`${field.label} `}
              <span aria-labelledby="required-field" className="text-danger">
                *
              </span>
            </>
          ) : (
            field.label
          )}
        </Form.Label>
        {field.as === "select" ? (
          <InputGroup>
            <Form.Control
              as={field.as}
              type={field.type}
              required={field.required}
              autoComplete={field.autoComplete}
              size={field.size}
              value={request[field.name]}
              onChange={(e) => {
                if (onChange !== undefined) {
                  onChange(e);
                } else if (setRequest !== undefined) {
                  setRequest({ ...request, [field.name]: e.target.value });
                } else {
                  throw new Error(
                    `No onChange event defined for input field: ${field.name}`
                  );
                }
              }}
              disabled={disabled}
            >
              {options !== undefined && options.length > 0 && (
                <>
                  <option value="" disabled>
                    Select...
                  </option>
                  {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
            {inputGroupAppendContent}
          </InputGroup>
        ) : (
          <Form.Control
            as={field.as}
            type={field.type}
            required={field.required}
            autoComplete={field.autoComplete}
            size={field.size}
            aria-label={field.ariaLabel}
            rows={field.rows}
            min={field.min}
            max={field.max}
            step={field.step}
            value={request[field.name]}
            onChange={(e) => {
              if (onChange !== undefined) {
                onChange(e);
              } else if (setRequest !== undefined) {
                setRequest({ ...request, [field.name]: e.target.value });
              } else {
                throw new Error(
                  `No onChange event defined for input field: ${field.name}`
                );
              }
            }}
            disabled={disabled}
          />
        )}
      </Form.Group>
    </>
  );
};

FormInput.defaultProps = {
  groupId: undefined,
  setRequest: undefined,
  onChange: undefined,
  options: [],
  inputGroupAppendContent: undefined,
};

export default FormInput;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PageNotFound = (): JSX.Element => {
  return (
    <main className="pageNotFound">
      <Container>
        <Row className="mt-4">
          <Col style={{ marginTop: "50px", minHeight: "200px" }}>
            <h1 className="text-center">
              <strong>Page Not Found</strong>
            </h1>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default PageNotFound;

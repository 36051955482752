import _ from "lodash";
import { useEffect, useState } from "react";
import Contact from "../../models/Contact";
import Undefinable from "../../models/Undefinable";
import ContactService from "../../services/ContactService";
import ShibbService, { LoggedInUser } from "../../services/ShibbService";
import useSafeAsync from "../useSafeAsync";

const useLoggedInUser = (): LoggedInUser => {
  const safeAsync = useSafeAsync();
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser>({
    ppid: "",
  });

  useEffect(() => {
    safeAsync(ShibbService.getLoggedInUser())
      .then((response) => {
        const loggedInUserResponse = response as LoggedInUser;
        if (loggedInUserResponse?.ppid) {
          return Promise.all([
            Promise.resolve(loggedInUserResponse),
            ContactService.getContactByPpid(
              loggedInUserResponse?.ppid
            ) as Undefinable<Contact>,
          ]);
        }
        return Promise.all([loggedInUserResponse, undefined]);
      })
      .then((response) => {
        const [loggedInUserResponse, contactResponse] = response as [
          LoggedInUser,
          Undefinable<Contact>
        ];
        setLoggedInUser(
          _.assign(loggedInUserResponse, {
            contactId: contactResponse?.id,
            firstName:
              contactResponse?.firstName || loggedInUserResponse?.firstName,
            lastName:
              contactResponse?.lastName || loggedInUserResponse?.lastName,
            email: contactResponse?.email,
            department: contactResponse?.department,
            emoryId: contactResponse?.emoryId,
          })
        );
      });
  }, [safeAsync]);

  return loggedInUser;
};

export default useLoggedInUser;

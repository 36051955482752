import moment from "moment";
import { ElementType } from "react";
import { RegistrarRequestFieldName } from "./RegistrarRequest";
import Undefinable from "./Undefinable";
import { getUserTypeByValue, UserType } from "./UserType";

class RegistrarRequestField {
  static readonly CUSTOMER_TYPE = new RegistrarRequestField(
    "customerType", // id
    "Customer Type", // label
    "select", // as
    undefined, // type
    true, // required
    undefined, // autoComplete
    "lg" // size
  );

  static readonly CUSTOMER_TYPE_DESCRIPTION = new RegistrarRequestField(
    "customerTypeDescription", // id
    "Customer Type Description", // label
    "input", // as
    "text", // type
    true, // required
    undefined, // autoComplete
    "sm" // size
  );

  static readonly REQUEST_TYPE = new RegistrarRequestField(
    "requestType", // id
    "Request Type", // label
    "select", // as
    undefined, // type
    true, // required
    undefined, // autoComplete
    "sm" // size
  );

  static readonly REQUEST_DESCRIPTION = new RegistrarRequestField(
    "requestDescription", // id
    "Description", // label
    "textarea", // as
    undefined, // type
    true, // required
    undefined, // autoComplete
    undefined, // size
    "Request Decription", // ariaLabel
    10 // rows
  );

  static readonly FIRST_NAME = new RegistrarRequestField(
    "firstName", // name
    "First Name", // label
    "input", // as
    "text", // type
    true, // required
    "given-name", // autoComplete
    "sm" // size
  );

  static readonly LAST_NAME = new RegistrarRequestField(
    "lastName", // name
    "Last Name", // label
    "input", // as
    "text", // type
    true, // required
    "family-name", // autoComplete
    "sm" // size
  );

  static readonly EMAIL = new RegistrarRequestField(
    "email", // name
    "Email", // label
    "input", // as
    "email", // type
    true, // required
    "email", // autoComplete
    "sm" // size
  );

  static readonly SCHOOL_OR_BUSINESS_UNIT = new RegistrarRequestField(
    "department", // name
    "School/Business Unit", // label
    "input", // as
    "text", // type
    true, // required,
    undefined, // autoComplete
    "sm" // size
  );

  static readonly SUBJECT_FIRST_NAME = new RegistrarRequestField(
    "subjectFirstName", // name
    "First Name", // label
    "input", // as
    "text", // type
    false, // required,
    "given-name", // autoComplete
    "sm" // size
  );

  static readonly SUBJECT_LAST_NAME = new RegistrarRequestField(
    "subjectLastName", // name
    "Last Name", // label
    "input", // as
    "text", // type
    false, // required,
    "family-name", // autoComplete
    "sm" // size
  );

  static readonly SUBJECT_EMAIL = new RegistrarRequestField(
    "subjectEmail", // name
    "Email", // label
    "input", // as
    "email", // type
    false, // required,
    "email", // autoComplete
    "sm" // size
  );

  static readonly SUBJECT_DOB = new RegistrarRequestField(
    "subjectDOB", // name
    "Date of Birth", // label
    "input", // as
    "date", // type
    false, // required,
    "bday", // autoComplete
    "sm" // size
  );

  static readonly SUBJECT_STUDENT_ID = new RegistrarRequestField(
    "subjectStudentId", // name
    "Student ID", // label
    "input", // as
    "text", // type
    false, // required,
    undefined, // autoComplete
    "sm" // size
  );

  static readonly SUBJECT_NAME_AT_TIME_OF_ENROLLMENT =
    new RegistrarRequestField(
      "subjectNameAtTimeOfEnrollment", // name
      "Previous name at time of enrollment", // label
      "input", // as
      "text", // type
      false, // required,
      "name", // autoComplete
      "sm" // size
    );

  static readonly SUBJECT_YEAR_LAST_ATTENDED_OR_GRADUATED =
    new RegistrarRequestField(
      "subjectYearLastAttendedOrGraduated", // name
      "Year last attended/graduated", // label
      "input", // as
      "text", // type
      false, // required,
      undefined, // autoComplete
      "sm", // size
      undefined, // ariaLabel
      undefined, // rows
      1900, // min
      moment().year(), // max
      1 // step
    );

  private constructor(
    public readonly name: RegistrarRequestFieldName,
    public readonly label: string,
    public readonly as: Undefinable<ElementType>,
    public readonly type: Undefinable<string>,
    public readonly required: boolean,
    public readonly autoComplete: Undefinable<string>,
    public readonly size: Undefinable<"sm" | "lg"> = "sm",
    public readonly ariaLabel: Undefinable<string> = undefined,
    public readonly rows: Undefinable<number> = undefined,
    public readonly min: Undefinable<number> = undefined,
    public readonly max: Undefinable<number> = undefined,
    public readonly step: Undefinable<number> = undefined
  ) {}
}

export default RegistrarRequestField;

export function getRequestorFieldsByUserType(
  userType: Undefinable<string>
): RegistrarRequestField[] {
  switch (getUserTypeByValue(userType)) {
    case UserType.FACULTY:
    case UserType.STAFF:
      return [
        RegistrarRequestField.FIRST_NAME,
        RegistrarRequestField.LAST_NAME,
        RegistrarRequestField.EMAIL,
        RegistrarRequestField.SCHOOL_OR_BUSINESS_UNIT,
      ];
    case UserType.ALUMNI:
      return [
        RegistrarRequestField.FIRST_NAME,
        RegistrarRequestField.LAST_NAME,
        RegistrarRequestField.EMAIL,
        RegistrarRequestField.SUBJECT_NAME_AT_TIME_OF_ENROLLMENT,
        RegistrarRequestField.SUBJECT_YEAR_LAST_ATTENDED_OR_GRADUATED,
        RegistrarRequestField.SUBJECT_DOB,
        RegistrarRequestField.SUBJECT_STUDENT_ID,
      ];
    case UserType.STUDENT:
      return [
        RegistrarRequestField.FIRST_NAME,
        RegistrarRequestField.LAST_NAME,
        RegistrarRequestField.EMAIL,
        RegistrarRequestField.SUBJECT_DOB,
        RegistrarRequestField.SUBJECT_STUDENT_ID,
      ];
    case UserType.OTHER:
    case UserType.PARENT:
    default:
      return [
        RegistrarRequestField.FIRST_NAME,
        RegistrarRequestField.LAST_NAME,
        RegistrarRequestField.EMAIL,
      ];
  }
}

export function getSubjectOfRequestFieldsByUserType(
  userType: Undefinable<string>
): RegistrarRequestField[] {
  switch (getUserTypeByValue(userType)) {
    case UserType.OTHER:
      return [
        RegistrarRequestField.SUBJECT_FIRST_NAME,
        RegistrarRequestField.SUBJECT_LAST_NAME,
        RegistrarRequestField.SUBJECT_YEAR_LAST_ATTENDED_OR_GRADUATED,
        RegistrarRequestField.SUBJECT_DOB,
        RegistrarRequestField.SUBJECT_STUDENT_ID,
      ];
    case UserType.FACULTY:
    case UserType.STAFF:
    case UserType.PARENT:
      return [
        RegistrarRequestField.SUBJECT_FIRST_NAME,
        RegistrarRequestField.SUBJECT_LAST_NAME,
        RegistrarRequestField.SUBJECT_EMAIL,
        RegistrarRequestField.SUBJECT_DOB,
        RegistrarRequestField.SUBJECT_STUDENT_ID,
      ];
    case UserType.ALUMNI:
    case UserType.STUDENT:
    default:
      return [];
  }
}

interface Contact {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  department?: string;
  ppid?: string;
  emoryId?: string;
}

export class ContactRecord implements Contact {
  readonly id: string;

  readonly firstName: string;

  readonly lastName: string;

  readonly email: string;

  readonly department: string;

  readonly ppid: string;

  readonly emoryId: string;

  constructor(that?: Contact) {
    this.id = that?.id || "";
    this.firstName = that?.firstName || "";
    this.lastName = that?.lastName || "";
    this.email = that?.email || "";
    this.department = that?.department || "";
    this.ppid = that?.ppid || "";
    this.emoryId = that?.emoryId || "";
  }
}

export default Contact;

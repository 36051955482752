import Undefinable from "./Undefinable";

export enum ShibbUserType {
  FACULTY = "Faculty",
  STAFF = "Staff",
  STUDENT = "Student",
}

enum NonShibbUserType {
  ALUMNI = "Alumni",
  PARENT = "Parent",
  OTHER = "Other",
}

export const UserType = { ...ShibbUserType, ...NonShibbUserType };
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserType = ShibbUserType | NonShibbUserType;

export function getUserTypeByValue(
  value: Undefinable<string>
): Undefinable<UserType> {
  switch (value) {
    case "Faculty":
      return UserType.FACULTY;
    case "Staff":
      return UserType.STAFF;
    case "Student":
      return UserType.STUDENT;
    case "Alumni":
      return UserType.ALUMNI;
    case "Parent":
      return UserType.PARENT;
    case "Other":
      return UserType.OTHER;
    default:
      return undefined;
  }
}

import React from "react";
import { Col, Row } from "react-bootstrap";
import RegistrarRequestField from "../../models/RegistarRequestField";
import { RegistrarRequest } from "../../models/RegistrarRequest";
import FormInput from "../FormInput";

interface FormInputGroupProps {
  id: string;
  label: string;
  fields: RegistrarRequestField[];
  request: RegistrarRequest;
  setRequest: (request: RegistrarRequest) => void;
  helperContent?: JSX.Element;
  disabled: boolean;
}

const FormInputGroup = ({
  id,
  label,
  fields,
  request,
  setRequest,
  helperContent,
  disabled,
}: FormInputGroupProps): JSX.Element => {
  return (
    <>
      {fields.length > 0 && (
        <fieldset>
          <legend>{label}</legend>
          {helperContent !== undefined && (
            <Row>
              <Col>{helperContent}</Col>
            </Row>
          )}
          <Row>
            {fields.slice(0, 2).map((field) => (
              <Col sm="12" lg="6" key={field.name}>
                <FormInput
                  groupId={id}
                  field={field}
                  request={request}
                  setRequest={setRequest}
                  disabled={disabled}
                />
              </Col>
            ))}
          </Row>
          {fields.slice(2).map((field) => (
            <FormInput
              key={field.name}
              groupId={id}
              field={field}
              request={request}
              setRequest={setRequest}
              disabled={disabled}
            />
          ))}
        </fieldset>
      )}
    </>
  );
};

FormInputGroup.defaultProps = {
  helperContent: undefined,
};

export default FormInputGroup;

import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import SalesforcePicklistValue from "../../models/SalesforcePicklistValue";
import Undefinable from "../../models/Undefinable";
import CaseService from "../../services/CaseService";
import useSafeAsync from "../useSafeAsync";

const dec2bin = (dec: number): string => {
  // eslint-disable-next-line no-bitwise
  return (dec >>> 0).toString(2);
};

const base64ToBits = (validFor: string): string => {
  if (!validFor) {
    return "";
  }

  const base64Chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  let validForBits = "";

  for (let i = 0; i < validFor.length; i += 1) {
    const thisChar = validFor[i];
    const val = base64Chars.indexOf(thisChar);
    const bits = dec2bin(val).padStart(6, "0");
    validForBits += bits;
  }

  return validForBits;
};

const filterRequestTypesByCustomerType = (
  customerType: string,
  customerTypes: SalesforcePicklistValue[],
  requestTypes: SalesforcePicklistValue[]
): SalesforcePicklistValue[] => {
  const filteredRequestTypes = [] as SalesforcePicklistValue[];
  requestTypes.forEach((requestType) => {
    const validForBits = base64ToBits(requestType.validFor);
    for (let i = 0; i < validForBits.length; i += 1) {
      if (
        i < customerTypes.length &&
        customerTypes[i].value === customerType &&
        validForBits[i] === "1"
      ) {
        filteredRequestTypes.push(requestType);
      }
    }
  });
  return filteredRequestTypes;
};

const useRequestTypes = (): [
  Undefinable<SalesforcePicklistValue[]>,
  (
    customerType: Undefinable<string>,
    customerTypes: Undefinable<SalesforcePicklistValue[]>
  ) => SalesforcePicklistValue[]
] => {
  const safeAsync = useSafeAsync();
  const [requestTypes, setRequestTypes] = useState<SalesforcePicklistValue[]>(
    []
  );
  const [filteredRequestTypes, setFilteredRequestTypes] =
    useState<Undefinable<SalesforcePicklistValue[]>>();
  const setRequestTypesByCustomerType = useCallback(
    (
      customerType: Undefinable<string>,
      customerTypes: Undefinable<SalesforcePicklistValue[]>
    ): SalesforcePicklistValue[] => {
      if (customerType && customerTypes) {
        const newFilteredRequestTypes = filterRequestTypesByCustomerType(
          customerType,
          customerTypes,
          requestTypes
        );
        setFilteredRequestTypes(newFilteredRequestTypes);
        return newFilteredRequestTypes;
      }
      return requestTypes;
    },
    [requestTypes, setFilteredRequestTypes]
  );

  useEffect(() => {
    safeAsync(CaseService.getRequestTypes())
      .then((response) => {
        const axiosResponse = response as AxiosResponse<
          SalesforcePicklistValue[]
        >;
        setRequestTypes(axiosResponse?.data || []);
        setFilteredRequestTypes([]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [safeAsync]);

  return [filteredRequestTypes, setRequestTypesByCustomerType];
};

export default useRequestTypes;

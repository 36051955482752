/* istanbul ignore file */
import { useCallback } from "react";
import useMountedState from "../useMountedState";

// https://www.benmvp.com/blog/handling-async-react-component-effects-after-unmount/
const useSafeAsync = (): ((promise: Promise<unknown>) => Promise<unknown>) => {
  const isMounted = useMountedState();

  const safeAsync = useCallback(
    (promise: Promise<unknown>) =>
      new Promise((resolve, reject) => {
        if (promise) {
          promise
            .then((value) => {
              if (isMounted()) {
                resolve(value);
              }
            })
            .catch((error) => {
              if (isMounted()) {
                reject(error);
              }
            });
        } else if (isMounted()) {
          resolve(undefined);
        }
      }),
    [isMounted]
  );

  return safeAsync;
};

export default useSafeAsync;

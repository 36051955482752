import Contact, { ContactRecord } from "../../models/Contact";
import BaseService from "../BaseService";

class ContactService extends BaseService {
  static async getContactByPpid(ppid?: string): Promise<Contact> {
    let contact = new ContactRecord();
    try {
      if (ppid) {
        const response = await super.get(
          "/api/contacts/{ppid}".replace("{ppid}", ppid)
        );
        contact = new ContactRecord(response?.data);
      }
    } catch {
      // ignore error
    }
    return contact;
  }
}

export default ContactService;

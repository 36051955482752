import { AxiosResponse } from "axios";
import BaseService from "../BaseService";

export interface AppInfo {
  name: string;
  version: string;
}

class AppService extends BaseService {
  static getAppInfo(): Promise<AxiosResponse<AppInfo>> {
    return super.get("/api/app-info");
  }
}

export default AppService;

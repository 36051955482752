import { useCallback, useEffect, useRef } from "react";

// https://www.benmvp.com/blog/handling-async-react-component-effects-after-unmount/
const useMountedState = (): (() => boolean) => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};

export default useMountedState;
